.privacy-policy-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
    margin-top: 60px; /* Ajusta según la altura de tu navbar */
  }
  
  .privacy-policy-container h2, .privacy-policy-container h3, .privacy-policy-container h4 {
    color: #000;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 72% */
    text-transform: uppercase;
  }
  
  .privacy-policy-container p {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
    /* 180% */
  }
  