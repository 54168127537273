@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.aboutUs {
  width: 100%;
}

.section-title {
  color: #000;
  font-family: "Antic Didone";
  font-size: 30px;
  text-transform: uppercase;
}

.aboutUs .horizontal-line {
  width: 60%;
  border-top: 1px solid #000;
  margin: 60px auto;
}

.subtitlesAboutUs {
  color: #000;
  text-align: left;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
}

.aboutUsSection {
  background: url('../images/backAboutUs.png') no-repeat center center;
  background-size: cover;
  padding: 80px 80px;
}

.aboutUs-row {
  padding: 100px 200px;
}

.aboutUs .aboutUs-section {
  height: auto;
  align-items: center;
  gap: 61px;
}

.textAbout {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.8; /* Ajusta el valor según necesites */
}

.aboutUs .aboutUsCard {
  border: none;
}

.aboutUs .card-title {
  color: #000;
  font-family: Montserrat;
  font-size: 30px;
  text-transform: uppercase;
}

.aboutUs .card-text {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5; /* Ajusta el valor según necesites */
}

.aboutUs img {
  width: 100%;
  height: auto;
  opacity: 0.5;
}

@media (max-width: 999px) {
  .aboutUsSection {
    padding: 60px 10px;
  }

  .textAbout {
    font-size: 18px;
  }

  .aboutUs-row {
    padding: 20px;
  }

  .card img {
    width: 100%;
    height: auto;
  }

  .card-body {
    padding: 1rem;
  }

  .aboutUs .aboutUs-section {
    height: auto;
    display: flex;
    padding: 0 15px;
    align-items: center;
    gap: 15px;
  }
  .aboutUs .horizontal-line {
    width: 80%;
  }

  .aboutUs .aboutUsCard {
    margin-bottom: 20px;
  }
  .subtitlesAboutUs{
    text-align: center;
  }
  .aboutUs .card-title{
    line-height: 1.2;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(10%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate__slideInFromRight {
  animation: slideInFromRight 2s ease-out;
}

@keyframes slideInFromCenterLeft {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate__slideInFromCenterLeft {
  animation: slideInFromCenterLeft 1s ease-out;
}

@keyframes slideInFromCenterRight {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate__slideInFromCenterRight {
  animation: slideInFromCenterRight 1s ease-out;
}
.buttonInfoAbout {
  margin-top: 40px;
  margin-right: 16px;
  border-radius: 10px;
  background: #BBA698;
  color: #FFF;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-color: #BBA698;
  text-transform: uppercase;
}