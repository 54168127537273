.menu {
  width: 100%;
}

/* src/styles/fonts.css */
@font-face {
  font-family: 'TAN Pearl';
  src: url('../styles/fonnts.com-tan-pearl.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.logo-title {
  font-family: 'TAN Pearl', sans-serif;
  color: #BBA698;
  margin: 0 0;
  text-transform: uppercase

}

.header-row {
  background-color: black;
  padding: 10px 0;
  width: 100%;
  margin: 0 0;
  /* Opcional: espacio alrededor del texto */
}

.header-text {
  color: #FFF;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.2px;
  /* 140% */
  letter-spacing: 3.9px;
}

.animate__slideInUp {
  --animate-duration: 1s;
  /* Duración de la animación */
}

.navbar-custom {
  background-color: white !important;
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
  color: black !important;
}

.navbar-custom .nav-link:hover {
  color: grey !important;
  text-decoration: none;
  border-bottom: 2px solid grey;
  /* Esto añade la línea debajo del enlace */
}

.custom-button {
  border-radius: 15px 0px;
  background: #BBA698;
  color: white !important;
  padding: 0.375rem 0.75rem;
  margin-right: 30px;
  border: none;
  text-align: center;
}

.custom-button:hover {
  background: #A88C7A;
}

.nav-links {
  display: flex;
  gap: 40px;
  /* Espacio entre los enlaces */
}

@media (max-width: 770px) {
  .menu {
    position: fixed;
    z-index: 1030;
    background-color: white;

    /* Ajusta el valor si es necesario */
  }
  
 .navbar-custom{
  margin-top: 22px;
 }
  .header-section {
    margin-top: 120px;
  }
}